import { useLocation } from '@remix-run/react'
import { useEffect, useState } from 'react'

interface UtmParams {
	utm_source: string
	utm_medium: string
	utm_campaign: string
}
export const useUtmParams = () => {
	const [utmParams, setUtmParams] = useState<UtmParams | null>(null)
	const location = useLocation()
	useEffect(() => {
		const searchParams = new URLSearchParams(location.search)
		const utmSource = searchParams.get('utm_source')
		const utmMedium = searchParams.get('utm_medium')
		const utmCampaign = searchParams.get('utm_campaign')

		setUtmParams({
			utm_source: utmSource || 'unknown',
			utm_medium: utmMedium || 'unknown',
			utm_campaign: utmCampaign || 'unknown',
		})
	}, [location.search])

	return utmParams
}
